import React from "react";
import { BiteAI } from "biteai";
import { Link } from "gatsby";
import { Callout, Navbar } from "@blueprintjs/core";

import logo from "../images/bite_ai_logo.png";
// import {BlankLayout} from '../components/Layout'

import { VisualSearch } from "react-biteai/lib/search/Demo";
import { Container, Section, Row } from "react-biteai/lib/containers/Layout";
import DefaultFooter from "../components/footer";
import { Text } from "react-biteai/lib/base/Typography";
import TokenPicker from "../components/TokenPicker";
import { Button } from "@blueprintjs/core";
import Layout from '../components/layout/base'

export default class DemoPage extends React.Component {
  state = {
    token: null
  };

  static defaultProps = {
    biteai: new BiteAI({
      // need to drop the trailing /
      url: `${DEVELOPER_API_URL}`.slice(0, -1)
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      token: props.biteai.token
    };
  }

  updateToken = token => {
    this.setState({ token });
    this.props.biteai.token = token;
  };

  renderError = (error, demo) => {
    console.log("Request error:", error, error.status);
    switch (error.status) {
      case 403:
        return (
          <Callout
            className={"fade-up"}
            style={{ margin: 30, maxWidth: 500 }}
            icon={"error"}
            intent={"danger"}
            title={"Authorization Error"}
          >
            Looks like the API Token used for this request is invalid.
            <br /> You can pick or generate a new token on the upper left of
            this screen
          </Callout>
        );
      case 401:
        return (
          <Callout
            className={"fade-up"}
            style={{ margin: 30, maxWidth: 500 }}
            icon={"error"}
            intent={"danger"}
            title={"Authorization Error"}
          >
            Looks like the API Token used for this request is invalid.
            <br /> You can pick or generate a new token on the upper left of
            this screen
          </Callout>
        );
      default:
        if (error.status >= 500)
          return (
            <Callout
              className={"fade-up"}
              style={{ margin: 30, maxWidth: 500 }}
              icon={"error"}
              intent={"danger"}
              title={"Something went wrong"}
            >
              Looks like the request failed due to a server issue
            </Callout>
          );
        else
          return (
            <Callout
              className={"fade-up"}
              style={{ margin: 30, maxWidth: 500 }}
              icon={"error"}
              intent={"danger"}
              title={"Something went wrong"}
            >
              There was something wrong with your request.
            </Callout>
          );
    }
  };

  render() {
    return (
      <Layout title={'Demos'}>
        <Section
          style={{
            backgroundColor: "#f8f8f8",
            padding: "0px 50px",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <Container>
            <Row justify={"space-between"} align={"center"}>
              <a href={"https://bite.ai"}>
                <img src={logo} alt="Bite AI Logo" height={50} />
              </a>
              <Navbar.Group>
                <Link to={"/"}>
                  <Button icon={"home"} minimal>
                    Dev Portal
                  </Button>
                </Link>

                <Navbar.Divider />

                <TokenPicker onSelect={this.updateToken} type={'user'}/>
              </Navbar.Group>
            </Row>
          </Container>
        </Section>
        <VisualSearch
          renderIntro={() => (
            <Callout
              className={"fade-up"}
              style={{ margin: 30, maxWidth: 500 }}
              icon={"media"}
              intent={"primary"}
              title={"Drop An Image Onto The Page"}
            >
              <Text>
                Drop an image onto this page or press on the polaroid to open a
                file picker to get started.
              </Text>
              <br />
              <Text />
            </Callout>
          )}
          renderError={this.renderError}
          biteai={this.props.biteai}
        />
        <DefaultFooter />
      </Layout>
    );
  }
}
