import gql from 'graphql-tag'
import {TokenFragment} from './DeveloperFragments'


export const AllTokensQuery = gql`
    query($type: String) {
        allTokens(type:$type) {
            edges {
                node {
                    ...TokenFragment
                }
            }
        }
    }
    ${TokenFragment}
`
export const CreateToken = gql`
    mutation($type: TokenTypes) {
        createToken(type: $type) {
            ...TokenFragment
        }
    }
    ${TokenFragment}
`